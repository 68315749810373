<template>
  <div>
    <vue-element-loading :active="appLoading" spinner="bar-fade-scale" color="#1976d2" size="128" is-full-screen />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-dialog v-model="editdialog" max-width="800px">
      <v-card>
        <v-card-title>
          <span class="headline">Edit Story</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-layout wrap justify-center>
              <v-flex xs12>
                <v-col cols="12">
                  <v-text-field v-model="editing.name" label="Story Name" required :rules="Rules" outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="editing.title" label="Story Title" required :rules="Rules"
                    outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field v-model="editing.caption" label="Story Caption" :rules="Rules" outlined></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select v-model="editing.storyCategory" :items="storyCategoryArray" item-text="name" item-value="_id"
                    dense outlined label="Story Category" required :rules="Rules"></v-select>
                </v-col>
                <v-col cols="12">
                  <ImageComp :singleImage="editing.image" @stepper="winStepper" :componentType="'mainImage'"
                    :heading="'Upload Image'" />
                </v-col>
                <v-col cols="12">
                  <v-textarea v-model="editing.titleText" label="Story Title Text" :rules="Rules" outlined></v-textarea>
                </v-col>
                <!-- <v-col cols="12">
                  <v-textarea
                    v-model="editing.serviceDescription"
                    label="Story Service Description"
                    :rules="Rules"
                    outlined
                  ></v-textarea>
                </v-col> -->
                <v-flex py-2 text-left pl-2 xs12 md12 lg12>
                  <span style="
                      padding-left: 10px;
                      text-align: left;
                      letter-spacing: 0.54px;
                      font-size: 12px;
                    ">Add Services</span>
                  <v-layout wrap justify-space-between>
                    <v-flex v-for="(item, i) in serviceArray" :key="i" pa-2>
                      <v-chip close @click:close="removeMaterial(i)">{{ item }}
                      </v-chip>
                    </v-flex>
                    <v-flex pa-2>
                      <v-dialog v-model="serviceDialog" max-width="500px" transition="dialog-transition">
                        <template v-slot:activator="{ on }">
                          <v-chip v-on="on" style="cursor: pointer" color="red" outlined>
                            <span style="color: #000000">
                              <v-icon>mdi-plus</v-icon> Add Services
                            </span>
                          </v-chip>
                        </template>
                        <v-card class="pa-2">
                          <v-layout wrap>
                            <v-flex xs12 text-left>
                              <span style="
                                  padding-left: 10px;
                                  text-align: left;
                                  letter-spacing: 0.54px;
                                  font-size: 13px;
                                ">Add Service</span>
                            </v-flex>
                            <v-flex xs12>
                              <v-text-field v-model="tags" dense outlined placeholder="Add Service" color="#FF1313">
                              </v-text-field>
                            </v-flex>
                          </v-layout>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="serviceDialog = false">
                              Cancel
                            </v-btn>
                            <v-btn color="blue darken-1" text @click="addSevice()">
                              Add
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-col cols="12">
                  <v-text-field v-model="editing.url" label="URL" outlined :rules="Rules"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <ImageComp :singleImage="editing.logo" @stepper="winStepper" :heading="'Upload Logo'"
                    :componentType="'logoImage'" />
                </v-col>
                <v-col cols="12">
                  <v-checkbox v-model="checkbox" :label="'Add a Voucher'"></v-checkbox>
                </v-col>
                <v-col cols="12" v-if="editing.voucher || checkbox == true">
                  <v-text-field v-model="editing.voucherOffer" label="Voucher Offer" outlined></v-text-field>
                </v-col>
                <v-col cols="12" v-if="editing.voucher || checkbox == true">
                  <v-text-field v-model="editing.voucher" label="Voucher Code" :rules="Rules" outlined></v-text-field>
                </v-col>
                <v-col cols="12" v-if="editing.voucher || checkbox == true">
                  <ImageComp :singleImage="editing.voucherImage" @stepper="winStepper" :heading="'Upload Voucher Image'"
                    :componentType="'voucherImage'" />
                </v-col>
                <v-col cols="12" v-if="editing.voucherExpiry || checkbox == true">
                  <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                    :return-value.sync="editing.voucherExpiry" transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field v-model="editing.voucherExpiry" placeholder="Voucher Expiry" readonly outlined dense
                        hide-details light v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker v-model="editing.voucherExpiry" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(editing.voucherExpiry)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="(editdialog = false), (tags = '')">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="editStory(editing)">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Stories
            <v-spacer></v-spacer>
            <v-flex text-right xs12 sm6 md4>
                <v-text-field
                  dense
                  v-model="search"
                  append-icon="mdi-magnify"
                  solo
                  label="Search Story"
                  single-line
                  hide-details
                ></v-text-field>
              </v-flex>
            <v-layout justify-end class="px-5">
              <v-dialog :retain-focus="false" v-model="dialog" persistent max-width="800px" :key="dialog">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    <span>Add Story</span>
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addcat" ref="addcat">
                    <v-card-title>
                      <span class="headline">Add Story</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field v-model="name" label="Story Name" required :rules="Rules"
                              outlined></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field v-model="title" label="Story Title" required :rules="Rules"
                              outlined></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field v-model="caption" label="Story Caption" outlined :rules="Rules"></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <v-select v-model="storyCategory" :items="storyCategoryArray" item-text="name"
                              item-value="_id" dense outlined label="Story Category" required :rules="Rules"></v-select>
                          </v-col>
                          <v-col cols="12">
                            <ImageComp :singleImage="mainImagePreview" @stepper="winStepper" :heading="'Upload Image'"
                              :componentType="'mainImage'" />
                          </v-col>
                          <v-col cols="12">
                            <v-textarea v-model="titleText" outlined label="Story Title Text" :rules="Rules"></v-textarea>
                          </v-col>
                          <v-flex py-2 text-left pl-2 xs12 md12 lg12>
                            <span style="
                                padding-left: 10px;
                                text-align: left;
                                letter-spacing: 0.54px;
                                font-size: 12px;
                              ">Add Services</span>
                            <v-layout wrap justify-space-between>
                              <v-flex v-for="(item, i) in serviceArray" :key="i" pa-2>
                                <v-chip close @click:close="removeMaterial(i)">{{ item }}
                                </v-chip>
                              </v-flex>
                              <v-flex pa-2>
                                <v-dialog v-model="serviceDialog" max-width="500px" transition="dialog-transition">
                                  <template v-slot:activator="{ on }">
                                    <v-chip v-on="on" style="cursor: pointer" color="red" outlined>
                                      <span style="color: #000000">
                                        <v-icon>mdi-plus</v-icon> Add Services
                                      </span>
                                    </v-chip>
                                  </template>
                                  <v-card class="pa-2">
                                    <v-layout wrap>
                                      <v-flex xs12 text-left>
                                        <span style="
                                            padding-left: 10px;
                                            text-align: left;
                                            letter-spacing: 0.54px;
                                            font-size: 13px;
                                          ">Add Service</span>
                                      </v-flex>
                                      <v-flex xs12>
                                        <v-text-field v-model="tags" dense outlined placeholder="Add Service"
                                          color="#FF1313">
                                        </v-text-field>
                                      </v-flex>
                                    </v-layout>

                                    <v-card-actions>
                                      <v-spacer></v-spacer>
                                      <v-btn color="blue darken-1" text @click="serviceDialog = false">
                                        Cancel
                                      </v-btn>
                                      <v-btn color="blue darken-1" text @click="addSevice()">
                                        Add
                                      </v-btn>
                                    </v-card-actions>
                                  </v-card>
                                </v-dialog>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-col cols="12">
                            <v-text-field v-model="url" label="URL" outlined :rules="Rules"></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <ImageComp :singleImage="logoImagePreview" @stepper="winStepper" :heading="'Upload Logo'"
                              :componentType="'logoImage'" />
                          </v-col>
                          <v-col cols="12">
                            <v-checkbox v-model="checkbox" :label="'Add a Voucher'"></v-checkbox>
                          </v-col>
                          <v-col cols="12" v-if="checkbox == true">
                            <v-text-field v-model="voucherOffer" label="Voucher Offer" outlined></v-text-field>
                          </v-col>
                          <v-col cols="12" v-if="checkbox == true">
                            <v-text-field v-model="voucher" label="Voucher Code" outlined></v-text-field>
                          </v-col>
                          <v-col cols="12" v-if="checkbox == true">
                            <ImageComp :singleImage="voucherImagePreview" @stepper="winStepper"
                              :heading="'Upload Voucher Image'" :componentType="'voucherImage'" />
                          </v-col>
                          <v-flex xs12 px-2 v-if="checkbox == true">
                            <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                              :return-value.sync="voucherExpiry" transition="scale-transition" offset-y min-width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-model="voucherExpiry" label="Voucher Expiry" readonly outlined
                                  v-bind="attrs" v-on="on"></v-text-field>
                              </template>
                              <v-date-picker v-model="voucherExpiry" no-title scrollable>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="menu = false">
                                  Cancel
                                </v-btn>
                                <v-btn text color="primary" @click="$refs.menu.save(voucherExpiry)">
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-menu>
                          </v-flex>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialog = false">
                        Close
                      </v-btn>
                      <v-btn color="blue darken-1" text :disabled="!addcat" @click="storyAdd()">
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-layout>
          </v-card-title>
          <div class="column pt-5 pa-5">
            <div class="row" v-for="(item, i) in user" :key="i">
              <v-img class="row1" :src="baseURL + item.image">
                <v-layout wrap justify-end>
                  <v-flex xs1 text-right>
                    <v-icon style="cursor: pointer; z-index: 9999" @click="editCategoryDialog(item)"
                      color="red">mdi-pencil</v-icon>
                  </v-flex>
                  <v-flex xs1 text-right>
                    <v-badge offset-x="20" offset-y="2" small overlap avatar color="#FF1313">
                      <template v-slot:badge>
                        <v-dialog v-model="item.dialogDelete" max-width="500px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-avatar style="cursor: pointer; z-index: 9999">
                              <v-icon small v-on="on" v-bind="attrs">mdi-close</v-icon>
                            </v-avatar>
                          </template>
                          <v-card>
                            <v-card-title style="
                                font-family: poppinsregular;
                                font-size: 15px;
                                color: #000;
                              ">Are you sure you want to delete this
                              Image?</v-card-title>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text small @click="item.dialogDelete = false">Cancel</v-btn>
                              <v-btn color="blue darken-1" text small @click="remove(item)">OK</v-btn>
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </template>
                      <template v-slot:placeholder>
                        <v-row class="fill-height ma-0" align="center" justify="center">
                          <v-progress-circular indeterminate color="#FF4343"></v-progress-circular>
                        </v-row>
                      </template>
                    </v-badge>
                  </v-flex>
                </v-layout>
                <v-layout wrap fill-height justify-center class="mainbody">
                  <v-flex xs12 align-self-end>
                    <v-card tile style="background: rgba(0, 0, 0, 0.5) !important">
                      <v-layout wrap justify-end pa-3>
                        <v-flex xs12 text-left align-self-end>
                          <span style="
                              color: white;
                              font-size: 14px;
                              font-weight: bold;
                            ">{{ item.name }}</span>
                        </v-flex>
                        <v-flex xs12 text-left align-self-end>
                          <span style="color: white; font-size: 12px">{{
                            item.title
                          }}</span>
                        </v-flex>
                        <v-flex xs12 text-left align-self-end>
                          <span style="color: white; font-size: 12px">Story Click Count : {{
                            item.storyClickCount
                          }}</span>
                        </v-flex>
                        <v-flex xs12 text-left align-self-end>
                          <span style="color: white; font-size: 12px">Website Click Count : {{
                            item.websiteClickCount
                          }}</span>
                        </v-flex>
                      </v-layout>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-img>
            </div>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- <div class="pt-2" v-if="pages > 1">
      <v-pagination
        :length="pages"
        :total-visible="7"
        v-model="currentPage"
        color="#FF0000"
      ></v-pagination>
    </div> -->
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      checkbox: false,
      editing: "",
      editId: "",
      appLoading: false,
      search: "",
      msg: "",
      file: null,
      file1: null,
      file2: null,
      coverFile: null,
      mainImagePreview: "",
      voucherImagePreview: "",
      productImagePreview: "",
      logoImagePreview: "",
      image: "",
      mainImage: "",
      voucherImage: "",
      logoImage: "",
      productImage: "",
      showsnackbar: false,
      ServerError: false,
      shopDialog: false,
      dialog: false,
      addcat: false,
      product: "",
      menu: false,
      productArray: [],
      titleText: "",
      serviceDescription: "",
      name: "",
      title: "",
      caption: "",
      storyCategory: "",
      storyCategoryArray: [],
      voucher: "",
      voucherExpiry: "",
      voucherOffer: "",
      tags: "",
      url:"",
      editingitem: "",
      editdialog: false,
      currentPage: 1,
      pages: 0,
      // count: 20,
      totalData: 0,
      totalRows: 0,
      user: [],
      selectedTags: [],
      Rules: [(v) => !!v || "Required"],
      curProduct: null,
      curProductEdit: null,
      imgId: "",
      shopDialogEdit: false,
      edit: null,
      editproduct: null,
      keyword: "",
      dialogDelete: false,
      serviceDialog: false,
      serviceArray: [],
    };
  },
  mounted() {
    this.getData();
    this.getCategory();
  },
  watch: {
    currentPage() {
      this.getData();
    },
    search(){
      this.getData();
    }
  },
  methods: {
    addSevice() {
      this.serviceArray.push(this.tags);
      this.tags = "";
    },
    removeMaterial(i) {
      this.serviceArray.splice(i, 1);
    },
    winStepper(window_data) {
      if (window_data.type == "mainImage") {
        this.file = window_data.selectedFiles;
      }
      if (window_data.type == "logoImage") {
        this.file1 = window_data.selectedFiles;
      }
      if (window_data.type == "voucherImage") {
        this.file2 = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/story/admin/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          // count: this.count,
          // page: this.currentPage,
          name:this.search
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getCategory() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/story/category/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.storyCategoryArray = response.data.data;
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = err.response.data.msg;
          this.msg = "Server Unreachable";
          this.showsnackbar = true;
        });
    },
    editCategoryDialog(item) {
      console.log("content=", item);
      this.edit = true;
      this.editing = item;
      this.serviceArray = this.editing.tags;
      if (this.editing.voucherExpiry) {
        this.editing.voucherExpiry = this.editing.voucherExpiry.slice(0, 10);
      }
      this.editdialog = true;
      this.storyCategory = item.storyCategory;
      this.editId = item._id;
      if (this.editing.voucher) {
        this.checkbox = true;
      }
    },
    remove(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/story/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.dialogDelete = false;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    storyAdd() {
      this.addDialog = false;
      var data = {};
      data["name"] = this.name;
      data["title"] = this.title;
      data["caption"] = this.caption;
      data["storyCategory"] = this.storyCategory;
      data["titleText"] = this.titleText;
      data["url"] = this.url;
      // data["serviceDescription"] = this.serviceDescription;
      data["tags"] = this.serviceArray;
      data["voucherOffer"] = this.voucherOffer;
      data["voucher"] = this.voucher;
      data["voucherExpiry"] = this.voucherExpiry;
      // data["tags"] = this.tags;
      axios({
        url: "/story/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.imgId = response.data.id;
            if (this.file) {
              this.uploadImage(this.imgId);
            }
            if (this.file1) {
              this.uploadLogo(this.imgId);
            }
            if (this.file2) {
              this.uploadVoucher(this.imgId);
            } else {
              this.getData();
            }
            this.msg = "Story Added!";
            this.showsnackbar = true;
            this.dialog = false;
            this.name = null;
            this.title = null;
            this.caption = null;
            this.storyCategory = null;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editStory(id) {
      this.addDialog = false;
      var data = {};
      data["name"] = this.editing.name;
      data["title"] = this.editing.title;
      data["caption"] = this.editing.caption;
      data["storyCategory"] = this.editing.storyCategory;
      data["titleText"] = this.editing.titleText;
      data["url"] = this.editing.url;
      data["serviceDescription"] = this.editing.serviceDescription;
      data["voucherOffer"] = this.editing.voucherOffer;
      data["voucher"] = this.editing.voucher;
      data["voucherExpiry"] = this.editing.voucherExpiry;
      data["tags"] = this.serviceArray;
      data["id"] = id._id;
      axios({
        url: "/story/edit",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.imgId = response.data.id;
            if (this.file) {
              this.uploadImage(this.imgId);
            }
            if (this.file1) {
              this.uploadLogo(this.imgId);
            }
            if (this.file2) {
              this.uploadVoucher(this.imgId);
            } else {
              this.getData();
            }
            this.msg = "Story Edited!";
            this.showsnackbar = true;
            this.editdialog = false;
            this.name = null;
            this.title = null;
            this.caption = null;
            this.file=null
            this.file1=null
            this.file2=null
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadImage(id) {
      let formData = new FormData();
      formData.append("photo", this.file);
      formData.append("id", id);
      axios({
        method: "POST",
        url: "/story/image",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            location.reload()
            // this.file=null
            // this.mainImagePreview=null
            // this.mainImage=null
            // formData=new FormData();
            this.getData();
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadLogo(id) {
      let formData1 = new FormData();
      formData1.append("photo", this.file1);
      formData1.append("id", id);
      axios({
        method: "POST",
        url: "/story/logo",
        data: formData1,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            location.reload()
            // this.file1=null
            // this.logoImagePreview=null
            // this.logoImage=null
            // formData1=new FormData();
            this.getData();
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    uploadVoucher(id) {
      let formData1 = new FormData();
      formData1.append("image", this.file2);
      formData1.append("id", id);
      axios({
        method: "POST",
        url: "/story/voucherImage",
        data: formData1,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.appLoading = false;
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            location.reload()
            // this.file2=null
            // this.voucherImagePreview=null
            // this.voucherImage=null
            // formData1=new FormData();
            this.getData();
          } else {
            this.appLoading = false;
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    rowClick(item) {
      this.$router.push("/productDetails/" + item._id);
    },
  },
};
</script>
<style scoped>
.column {
  column-count: 4;
  column-gap: 1em;
}

.row {
  margin: 0 0 1em !important;
  width: 100% !important;
  height: 100%;
  display: inline-block !important;
}

.mainbody {
  position: absolute;
  top: 0;
  /* Header Height */
  bottom: 0;
  /* Footer Height */
  width: 100%;
}
</style>
